import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import ErrorPage from '../ErrorPage/ErrorPage'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useGetGameMutation } from '../../../store/game/actions'

import './Game.css'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { setGame } from '../../../store/game/gameSlice'
import { CloseIcon } from '../../component/icones'
import { useState } from 'react'
import Draggable from 'react-draggable'
import { DragIndicatorIcon } from '../../component/icones'
import { Player } from '@lottiefiles/react-lottie-player'

const Game = () => {
	const dispatch = useDispatch()

	const [getGame, { isError }] = useGetGameMutation()

	const [showLoader, setShowLoader] = useState(true)

	const game = useSelector(state => state.game)

	const location = useLocation()

	const { slug } = useParams()

	const [gameIframe, setGameIframe] = useState('')

	const getGamee = async slug => {
		try {
			const res = await getGame(slug).unwrap()
			return res.data
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		// Show the loader for 6 seconds
		const loaderTimeout = setTimeout(() => {
			setShowLoader(false)
		}, 2000) // 6 seconds delay in milliseconds

		return () => clearTimeout(loaderTimeout)
	}, [])

	const api_url = process.env.REACT_APP_API_SERVER_URL

	const encodeData = data => {
		return Object.keys(data)
			.map(function (key) {
				return [key, data[key]].map(encodeURIComponent).join('=')
			})
			.join('&')
	}

	useEffect(() => {
		try {
			getGamee(slug).then(data => {
				if (data) {
					window.addEventListener('message', function (event) {
						console.log(
							'[Timepass Web] Game -> Platform Call Received: ',
							event.data.func,
						)
						if (event.source !== window.parent) {
							if (window.parent) {
								window.parent.postMessage(event.data, '*');
							}
						}
					})
					const queryParamData = {
						game_id: data?.uuid,
						auth_type: 'basic',
						score_sync: data?.score_sync,
						state_sync: data?.state_sync,
						user_id: location?.search?.split('=')[1] ?? '',
						client_id: process.env.REACT_APP_CLIENT_ID,
						tournament_mode: false,
						show_ads: false,
						show_purchase_options: false,
					}
					const queryParam = encodeData(queryParamData)
					setGameIframe(
						`${data?.url}?api_url=${api_url}&${queryParam}`
					)
					dispatch(setGame(data))
					if (!location.state) {
						const properties = {
							GAME_NAME: data?.name,
							GAME_FROM: 'Game',
							GAME_UUID: data?.uuid,
							SEGMENT_NAME: 'Direct Landing',
						}
						sendAnalytics('track', properties, 'Play Game', [
							'mixpanel',
						])
					}
				}
			})
		} catch (error) {
			console.error(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (
			location?.search?.split('=')[0]?.substring(1) === 'userId' &&
			location?.search?.split('=')[1]
		) {
			const id = location?.search?.split('=')[1]
			sendAnalytics('identify', {}, id, ['mixpanel'])
		}
	}, [location])

	return (
		<div className='mx-auto'>
			{showLoader && (
				<Player
					src='/animation_llkrpnwl.json'
					className='player fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
					loop
					autoplay
					style={{ height: '200px', width: '200px' }}
				/>
			)}
			{!showLoader && (
				<div className='p-0 max-w-full'>
					<Helmet>
						<title>{`${game?.data?.property?.name} - ${process.env.REACT_APP_TITLE}`}</title>
						<meta
							name='description'
							content={`${game?.data?.property?.subtitle}`}
						/>
					</Helmet>
					{process.env.REACT_APP_SHOW_GAME_CONTROL_ICON === "true" && <Draggable>
						<div className='fixed top-1/4 right-0 z-10 flex items-center gap-2 bg-black rounded-md'>
							<div className='bg-black pl-3 w-full h-full'>
								<DragIndicatorIcon/>
							</div>
							<div
								className=' bg-[#EA5402] text-white flex w-fit items-center p-2 cursor-pointer'
								onClick={() => {
									window.history.back()
								}}
								onTouchStart={() => {
									window.history.back()
								}}>
								<CloseIcon height='1.5rem' width='1.5rem'/>
								{/* <FaHome color="white" size={20} /> */}
							</div>
						</div>
					</Draggable>}
					{/*/***********Home btn and info ***********************************/}
					{!game?.data?.multiplayer && (
						<iframe
							title={slug}
							className='game-frame no-scrollbar absolute top-0 h-full sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden'
							src={gameIframe}
							key={gameIframe}
						/>
					)}
				</div>
			)}
			{isError && <ErrorPage></ErrorPage>}
		</div>
	)
}

export default Game
