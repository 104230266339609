// import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// export const getGame = createAsyncThunk(
//   "game/getGame",
//   async (slug, { rejectWithValue }) => {
//     try {
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_API_SERVER_URL}/game/slug/${slug}?platform=B2BW&language=en`
//       );
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

import { apiSlice } from '../api/apiSlice'

export const gameSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGame: builder.mutation({
      query: (slug) => ({
        url: `/game/slug/${slug}?platform=B2BW&language=en`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetGameMutation } = gameSlice
